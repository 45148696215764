import useBreakpoints from "@Hooks/useBreakpoints"
import { DOMElementIds } from "@Types/common"
import { cn } from "@Utils/theme"

type ContainerProps = React.HTMLAttributes<HTMLDivElement>

const Container = ({ children, className, ...props }: ContainerProps) => {
  const { isMeasured } = useBreakpoints()

  return (
    <>
      <div
        id={DOMElementIds.RootLayout}
        className={cn(
          "relative z-[2] bg-background w-full h-[100svh] min-h-[100svh] transition-blur duration-300",
          isMeasured ? "opacity-1" : "opacity-0",
          className
        )}
        {...props}
      >
        {children}
      </div>
      <div id={DOMElementIds.LoadingStatus} />
    </>
  )
}

export default Container
