import React from "react"
import LogoTextSvg from "@Public/svg/bb-logo-text.svg"
import { cn } from "@Utils/theme"
import Image from "next/image"
import useBreakpoints from "@Hooks/useBreakpoints"

export interface LogoProps {
  onClick?: () => void
  className?: string
  style?: React.CSSProperties
}

const Logo: React.FC<LogoProps> = ({ onClick, className, style }) => {
  const { isMobile, isTablet } = useBreakpoints()

  return (
    <div className={cn("inline-flex items-center", className)} style={style}>
      <span
        role={onClick ? "button" : undefined}
        tabIndex={onClick ? 0 : undefined}
        onClick={onClick}
        className="inline-flex items-center gap-[1.6rem]"
      >
        <Image
          src="/images/bb-logo-blue.png"
          alt="logo"
          width={isMobile || isTablet ? 20 : 28}
          height={isMobile || isTablet ? 24 : 32}
          style={{
            width: isMobile || isTablet ? "20px" : "28px",
            height: isMobile || isTablet ? "24px" : "32px",
          }}
        />
        <LogoTextSvg
          height={isMobile || isTablet ? "1.8rem" : "2.4rem"}
          width={isMobile || isTablet ? "12rem" : "15.6rem"}
          fill="#111f7f"
          style={{ color: "#111f7f" }}
        />
      </span>
    </div>
  )
}

export default Logo
