import Head from "next/head"
import { BlitzLayout } from "@blitzjs/next"
import { IntercomProvider } from "./IntercomProvider"
import Container from "./Container"

interface Props {
  title?: string
  hideIntercom?: boolean
}

const Layout: BlitzLayout<Props> = ({ title, children, hideIntercom }) => {
  return (
    <>
      <Head>
        <title>{title || "BrokerBlocks"}</title>
      </Head>
      {hideIntercom ? (
        <Container>{children}</Container>
      ) : (
        <IntercomProvider>
          <Container>{children}</Container>
        </IntercomProvider>
      )}
    </>
  )
}

export default Layout
